import React from 'react';
import styled from 'styled-components';
// import logo from './assets/MichaelColor.png';
// import logo from './assets/MichaelBW2019.png';
// import logo from './assets/MichaelColor2022.png';
import logo from './assets/Michael2021.png';
import './App.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';

const Body = styled.div`
  background-color: #1A1A1C;
  border-radius: 4px;
  height: 100%;
`;

const HeaderText = styled.span`
  font-family: 'Raleway';
  color: white;
  font-size: 56px;

  @media screen and (max-width:599px) {
    font-size: 48px;
  }
`;

const SubText = styled.span`
  font-family: 'Raleway';
  color: white;
  font-size: 20px;
`;

const BodyText = styled.div`
  font-family: 'Raleway';
  color: white;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-top: 64px;
  max-width: 500px;

  @media screen and (max-width:599px) {
    text-align: left;
    margin-top: 24px;
  }
`;

const HeadShotContainer = styled.div`
  margin: auto;
  width: 100%;
  position: relative;
`;

const HeadShot = styled.img`
  width: 100%;
`;

const HeadShotBlock = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0px;
  top: 0px;
  box-shadow: inset -10px -10px 10px 20px #1A1A1C;
`;

const FlexContainer = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;

  @media screen and (max-width:599px) {
    flex-direction: column;
  }
`;

const FlexSectionLeft = styled.div`
  flex-basis: 60%;
  align-self: center;
  padding-left: 10%;
  order: 1;

  @media screen and (max-width:599px) {
    text-align: center;
    padding: 0 24px;
    order: 2;
  }
`;

const FlexSectionRight = styled.div`
  flex-basis: 40%;
  align-self: center;
  margin: 48px;
  order: 2;

  @media screen and (max-width:599px) {
    margin: 0 64px;
    text-align: center;
    order: 1;
  }
`;

const Footer = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  width: 50%;

  i {
    margin 8px;
  }

  a, a:visited {
    color: white;
    -webkit-transition: color .25s;
    -moz-transition: color .25s
    -o-transition: color .25s
    transition: color .25s
  }

  a:hover {
    color: #1CCEC7;
  }
`;


const SocialButton = ({ href, alt, icon }) => (
  <a href={href} alt={alt} target="_blank" rel="noopener noreferrer"><i className={`fa ${icon} fa-1x`}></i></a>
)

function App() {
  return (
    <div className="App">
      <Body>
        <FlexContainer>
          <FlexSectionLeft>
            <HeaderText> Michael Welling </HeaderText>
            <br />
            <SubText>Travel | Code | Live</SubText>
            <BodyText>
              I'm like a well-tempered Tyrion Lanister; I code and I know things.
              I've got a clever tongue, not to mention a taste for travel and red wine. <br />
              <br />
              Based out of Indianapolis, I love helping others and fixing problems.
            </BodyText>
          </FlexSectionLeft>
          <FlexSectionRight>
            <HeadShotContainer>
              <HeadShot src={logo} />
              <HeadShotBlock />
            </HeadShotContainer>
          </FlexSectionRight>
        </FlexContainer>
        <Footer>
          <SocialButton href="https://www.facebook.com/michael.welling.9" alt="facebook" icon="fa-facebook" />
          <SocialButton href="https://www.instagram.com/mewelling7" alt="instagram" icon="fa-instagram" />
          <SocialButton href="https://www.linkedin.com/in/mewelling" alt="linkedin" icon="fa-linkedin" />
          <SocialButton href="https://github.com/mewelling" alt="github" icon="fa-github" />
          {/* <SocialButton href="mailto:michael@mewelling.com" alt="email" icon="fa-paper-plane-o" /> */}
        </Footer>
      </Body>
    </div>
  );
}

export default App;
